import React from 'react';

const PersonBio = ({ name, age, occupation, works_at, hobbies, contact, socialLinks, profilePicture }) => {
  return (
    <div className="bio-card">
      <img src={profilePicture} alt={`${name}'s Profile`} className="profile-picture" />
      <h2>{name}</h2>
      <p><strong>Age:</strong> {age}</p>
      <p><strong>Occupation:</strong> {occupation}</p>
      <p><strong>Works At:</strong> {works_at}</p>
      <p><strong>Email:</strong></p>
      {/* <p>📞 {contact.phone}</p> */}
      <p>📧 <a href={`mailto:${contact.email}`}>{contact.email}</a></p>
      <p><strong>Social Media:</strong></p>
      <ul>
        <li><a href={socialLinks.linkedin} target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
        <li><a href={socialLinks.github} target="_blank" rel="noopener noreferrer">GitHub</a></li>
      </ul>
    </div>
  );
};

export default PersonBio;
