import React from 'react';
import PersonBio from './PersonBio';

function App() {
  return (
    <div className="app-container">
      <PersonBio
        name="Satwik Padhy"
        age={24}
        occupation="Senior Software Engineer"
        works_at="Kfin Technologies Limited"
        contact={{
          // phone: "+91-7978729435",
          email: "contact@satwikpadhy.in",
        }}
        socialLinks={{
          linkedin: "https://www.linkedin.com/in/satwik-padhy-20492711a/",
          github: "https://github.com/satwikpadhy",
        }}
        profilePicture="pic.jpg"
      />
    </div>
  );
}

export default App;
